import { Link } from 'react-router-dom';

import calendarIcon from '../../assets/icons/calendar.svg';
import Logo from '../../assets/images/big-logo.png';
import CompanyLogos from '../../assets/images/company-logos.png';
import './sweepstakes.scss';
import { useSweepstakes } from './sweepstakes.hook';
import { FormInput } from '../../components/atoms/form-input/form-input';
import { SweepstakesSchemaType } from './sweepstakes.schema';
import { FormError, MaskedFormInput } from '../../components';
import { Loader } from '../../components/atoms/loader/';

export default function Sweepstakes() {
  const { control, formErrors, isSubmitting, ticketId, submit, date } =
    useSweepstakes();

  return (
    <form onSubmit={submit}>
      <section className='sweepstakes-container'>
        <div className='sweepstakes-container__left'>
          <Link to={`/${ticketId}`}>
            <img className='sweepstakes__logo' src={Logo} alt='Jackpot logo' />
          </Link>
          <div className='sweepstakes-container__left__header'>
            <p className='sweepstakes__tertiary-title'>JACKPOTNY.BUZZ</p>
            <h1 className='sweepstakes__primary-title'>SWEEPSTAKES ENTRY</h1>
            {/* TODO: Remove commented code when the client requests it */}
            {/* {
              date ?  
              <p className='sweepstakes__data-text'>
                <img src={calendarIcon} alt='calendar icon' /> {date}
              </p>
            : ''
            } */}
          </div>

          <h2 className='sweepstakes__secondary-title'>
            ENTER TO WIN SUBSCRIPTION-BASED PRIZES!
          </h2>

          <img
            className='sweepstakes__company-logos'
            src={CompanyLogos}
            alt='company logos'
          />
        </div>

        <div className='sweepstakes-container__right'>
          <div className='sweepstakes-container__right__header'>
            <p className='sweepstakes__tertiary-title'>JACKPOTNY.BUZZ</p>
            <h1 className='sweepstakes__primary-title'>SWEEPSTAKES ENTRY</h1>
            {/* TODO: Remove commented code when the client requests it */}
            {/* {
              date ?  
              <p className='sweepstakes__data-text'>
                <img src={calendarIcon} alt='calendar icon' /> {date}
              </p>
            : ''
            } */}
          </div>
          <div className='sweepstakes-container__right__form'>
            <FormInput<SweepstakesSchemaType>
              className='sweepstakes-container__right__form__input'
              control={control}
              errors={formErrors}
              name='firstName'
              placeholder='First Name'
            />
            <FormInput<SweepstakesSchemaType>
              className='sweepstakes-container__right__form__input'
              control={control}
              errors={formErrors}
              name='email'
              placeholder='Email'
            />
            <MaskedFormInput<SweepstakesSchemaType>
              className='sweepstakes-container__right__form__input'
              control={control}
              errors={formErrors}
              name='phone'
              placeholder='Phone'
              mask='(999)-999-9999'
            />
            <MaskedFormInput<SweepstakesSchemaType>
              className='sweepstakes-container__right__form__input'
              control={control}
              errors={formErrors}
              name='zipCode'
              placeholder='Zip Code'
              mask={'99999-99999'}
            />
            <div className='sweepstakes-container__right__form__checkbox__container'>
              <FormInput<SweepstakesSchemaType>
                className='sweepstakes-container__right__form__checkbox__container__checkbox'
                control={control}
                errors={formErrors}
                name='receiveEmails'
                type='checkbox'
              />
              <span className='sweepstakes-container__right__form__checkbox__container__text'>
                YES, I want to receive emails with product launch details and
                other promotions. i have read and agreed to the jackpotny.buzz
                privacy policy.
              </span>
            </div>
            <FormError<SweepstakesSchemaType>
              name='acceptedOfficialRules'
              errors={formErrors}
            />
            <div className='sweepstakes-container__right__form__checkbox__container'>
              <FormInput<SweepstakesSchemaType>
                className='sweepstakes-container__right__form__checkbox__container__checkbox'
                control={control}
                errors={formErrors}
                name='acceptedOfficialRules'
                type='checkbox'
                showError={false}
              />
              <span className='sweepstakes-container__right__form__checkbox__container__text'>
                I HAVE READ AND AGREED TO THE OFFICIAL RULES.
              </span>
            </div>
            <div>
              {isSubmitting ? (
                <Loader />
              ) : (
                <button className='sweepstakes-container__button'>
                  SUBMIT
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}
